.account-controls{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: red solid 4px;
    /* height: calc(100vh - var(--navbar-height)); */
    background-color: rgba(189, 189, 189, 0.321);
    padding-bottom: 2em;
}

.account-controls *{
    outline: red 0px solid;
}

.background{
    background-color: rgb(35, 35, 35);
    height:100vh;
}

.background img{
    display: block;
    max-height: 100vh;
    max-width: 105vw;
    opacity: 0.7;
}

.account-controls, .account-controls *{
    border: 0px solid red;
}

.homepage-content{
    position: absolute;
    top: clamp(10vh, 16%, 25%);
    left: 50%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    gap: 1rem;

    background-color: rgba(0, 0, 0, 0.828);
    padding:1.5rem 2.5rem;
    border-radius: 10px;
    transform: translate(-50%, -50%);

}

.hero-header{
    position:absolute;
    top:1em;
    left:50%;
    transform: translate(-50%, 0);
    color: white;
    background-color: rgba(0, 0, 0, 0.828);
    padding: 0.35em 0.5em;
    border-radius: 5px;

}

.homepage-info{
    color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap:1.5rem;
    font-size: 1.2rem;
    min-width: 300px;

    /* border: 2px solid blue; */
    align-items: start;
    justify-content: center;
}

.instructions-container{
    /* border: solid 2px red; */
}

.homepage-info ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap:1.5rem;
    /* border: 2px solid green; */
}

.homepage-info li{
    border: 0px solid green;
    list-style: none;
}

.homepage-more-info{
    border: 0px solid purple;
    width: 100%;
}

.create-account-form{
    /* position: absolute;
    top:10%;
    transform: translate(0,0%); */

    font-size: 17px;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column;
    gap:1.5em;


    width: 400px;

    padding:1em;
    margin:1em;



    /* width: clamp(80%, 400px, 500px); */
    
    border-radius: 5px;
    /* box-shadow: 0px 1px 5px 2px rgba(55, 55, 55, 0.456); */

    /* background-color: gainsboro; */
    color: white;


}

.mobile-only{
    width: 0;
    height: 0;
}

.field{
    display: flex;
    flex-direction: column;
    gap:0.2em;
}

.field input{
    height:2em;
    padding-left: 0.25em;
    font-size: 1.1em;
    border: none;
    border-bottom: 1px solid grey;
    border-bottom: 1px solid grey;
    min-width: 20rem;
    background-color: rgb(239, 239, 239);
}

.field span{
    padding-left:0.5em;
    color: grey;
}

.field label{
    margin-bottom: 0.25em;
}

.account-buttons-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:5px;
    margin-top:0.5rem;
}

.account-buttons-container button{
    height:2.25em;
    font-size: 1.05em;

    border: none;
    transition: 0.4s;
}

.account-buttons-container button:hover{
    cursor: pointer;
    background-color: var(--css-red);
}


.account-controls{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logged-in-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logout-button{
    position: fixed;
    top: 0;
    top: var(--navbar-height);
    left:10px;

}

.instructions-container{
    transition: 0.3s;
}

.instructions-span{
    color:gold
}

.leaderboard-preview-container{
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1em;
}

.leaderboard-preview-container table{
    font-size: 1.25em;
    padding:0.75em;
    border: rgba(63, 63, 63, 0.574) 1px solid;
}


.view-full-leaderboard-button{
    background: none;
    font-size: 1.1em;
    text-decoration: underline;

}

.view-full-leaderboard-button:hover{
    background: none;
}

.patch-notes{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding: 1em;
    gap: 1em;
}

.patch-notes > p {
    text-align: center;
    text-wrap: nowrap;
    margin: 0.5em;
}

.patch-notes ul{
    /* width: 85%; */
    outline: 0px solid red;
    display: flex;
    flex-direction: column;
}

.patch-notes li {
    /* list-style: none; */
}

@media  screen and (max-width:500px) {

    .background{
        width: 100%;
        height: 100%;
    }

    .background img{
        width: 100%;
    }

    .homepage-content{
        width: 95%;
        border: red 0px solid;
        position: absolute;
        flex-direction: column-reverse;
        background: none;
        padding: 0rem;
        top:12vh;
        left:50%;
        transform: translate(-50%, 0);
        gap: 2rem;
        /* left: 50%; */
    }

    .hero-header{
        max-width: 90%;
        font-size: 1.6rem;
        text-align: center;
        border-radius: 3px;
        width: 80%;
    }

    .create-account-form{
        width: 100%;
        font-size: 19px;
        top:0%;
        transform: translate(0, 0);
        background: rgba(1, 1, 1, 0.579);
        color: white;
        padding: 1.35em 1em 1.25em 1em;
        border: 0px solid green;
        margin: 1em 0 0 0;
    }

    .field{
        color: rgb(231, 231, 231);
        gap:0em;
    }

    .field input{
        /* background: none; */
        color: white;
        background: none;
        padding: 0em 0 0 0.05em;
    }

    .field input:focus{
    
        color: rgb(255, 255, 255);
        border-bottom-color: rgb(0, 204, 255);
    }

    .account-buttons-container button{
        height:2.25em;
        font-size: 1.05em;
    
        border: none;
        transition: 0.4s;
    }
    
    .account-buttons-container button:hover{
        cursor: pointer;
        background-color: var(--css-red);
    }

    .login-button{
        color: white;
        background: rgb(168, 37, 37);
    }

    .mobile-only{
        height:100vh;
        /* object-fit: contain; */
        object-fit: cover;
    }

    .desktop-only{
        width: 0;
        height: 0;
    }



    .homepage-content ul{
        font-size: 1.3rem;
        padding: 0.25rem;
    }

    .homepage-more-info{
        text-shadow: 0px 2px rgba(0, 0, 0, 0.777);
    }

    .instructions-container{
        padding:1rem;
        background: black;
        border-radius: 5px;
        transition: 0.3s;
    }

    .hidden-instructions{
        display: none;
        transform: translate(-200px, 50%);
    }

    .visible-instructions{
        display: flex;
        transition: 0.3s;
        transform: translate(0px, 0);
    }

    .instructions li{
        display: block;
        width: 90%;
        margin: auto;
    }

}

    @media  screen and (max-width:1200px){
        .homepage-content{
            flex-direction: column-reverse;
            top:10vh;
            transform: translate(-50%, 0);
        }
    }