.player-card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap:0.75em;

    width: 100%;

    min-width:8em;
    /* padding:0.75em; */
    padding:0 0 0.75em 0;
    color: white;
    background: white;
    border-radius: 1px;
    box-shadow: 0px 2px 2px 2px rgba(65, 65, 65, 0.178);
    /* z-index:2; */
    background: rgba(0, 0, 0, 0.781);
    /* backdrop-filter: blur(3px); */
    border-radius: 10px;
    outline: 2px solid rgb(245, 245, 245);
    /* aspect-ratio: 1/1; */
}

.active-team .player-card{
    /* outline: red 1px solid; */
    /* transform: rotate3d(1, 1, 1, 90deg); */
    animation-name: rotatio;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    transform: center;
}

@keyframes rotatio {
    from{
        opacity: 0.5;
        rotate: 0 1 1 33deg;
    }
    to{
        opacity: 1;
        rotate: 0 0 0 0 0deg;
    }
}


.player-info{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    gap:0.5em;
    background: rgb(30, 30, 30);
    background: rgb(2, 6, 32);
    background: rgb(245, 245, 245);
    color: white;
    color: black;
    border-radius: 10px 10px 0 0 ;
    padding:0.25em 0.5em;
}

.player-info .vertical-divider{
    background: black;
    height: 10px;
    width:1px;
}


.player-name{
    text-transform: capitalize;
    padding:0;
    margin: 0;
    white-space: nowrap;
}


.player-position{
    /* text-transform: capitalize;
    font-weight: bold;
    font-size: 1.3em; */
    text-transform: uppercase;
    font-size: 0.75em;
    padding:0;
    margin: 0;
}

.player-card.add .remove-player-button, .player-card.add .captain-player-button{
    display:none;
}

.player-card.remove .add-player-button{
    display: none;
}

.captain-player-button img{
    opacity: 0.6;
    transition:0.3s;
}


.captain-player-button:hover img{
    /* height:25px;
    width: 25px; */
    opacity: 0.8;
}

.remove-player-button{

}

.icon-button img{
    /* height: 100%; */
    height:20px;
    width:20px;
    filter: invert(1);

}

.icon-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    /* border: 2px solid green; */
    height: 100%;
}

.icon-button:hover{
    cursor: pointer;
}

.button-label{
    font-size: 0.8em;
    text-align: center;
}

.player-card.captain .captain-player-button img{
    opacity: 1;
}

.player-card.captain .player-info{
    background:rgb(255, 230, 91);
    color: black;
    font-weight: 500;

}

.player-card.captain{
    outline: 2px solid rgb(255, 229, 83);
}

.player-card.captain .vertical-divider{
    background: black;
}

.player-card.captain .player-points{
    font-weight: 500;
}

.modifiable .player-points{
   display: none;
   font-weight: 500;
}

.unmodifiable .gameweek-modifier{
    display: none;
}

.modifiable .breakdown-button {
    display: none;
}

.player-card-options{
    border: 0px solid red;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content:center;
    gap:20%;
    align-items: center;
    
}
.player-card-options > * {
    border: 0px solid red;

}


.player-card.unmodifiable:hover .player-performance-viewer{
    visibility: visible;
    transform: translateZ(-200);
}

.player-performance-viewer{
    /* width: ; */
    cursor:default;
    visibility: hidden;
    display: none;
    background: white;
    color: black;
    position: absolute;
    left: 50%;
    top: 18%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    padding:0.5em 0.5em 0.5em 0.5em;
    min-width: 9em;
    gap: 0.25em;
    z-index: 6;
    box-shadow: 0px 0px 2px 1px grey;
    user-select: none;
}



.player-performance-viewer hr{
    width:100%;

    height: 1px;
    background: rgb(55, 55, 55);
    margin: 0.3em 0;
}


.player-performance-viewer p {
    width: 100%;
    /* white-space: nowrap; */
    text-align: left;
    /* white-space: nowrap; */
}

p.player-performance-name{
    text-transform: capitalize;
    text-align: center;
    width: 100%;
    font-weight: bold;
    margin-bottom: 0.3em;
}

.bonusMessage{
    white-space: wrap;
}

.bonusMessage p{
    color: grey;
    font-style: italic;
}

.bonusMessage p{
    /* display: block; */
    min-width: 6em;
    /* color: red; */
    border: 0px solid red;
    white-space: wrap !important;
    width: 100%;
    height: fit-content;
    padding-bottom: 0.5em;
}