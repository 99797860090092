.player-card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap:0.75em;

    width: 100%;

    min-width:8em;
    /* padding:0.75em; */
    padding:0 0 0.75em 0;
    color: white;
    background: white;
    border-radius: 1px;
    box-shadow: 0px 2px 2px 2px rgba(65, 65, 65, 0.178);
    /* z-index:2; */
    background: rgba(0, 0, 0, 0.781);
    /* backdrop-filter: blur(3px); */
    border-radius: 10px;
    outline: 2px solid rgb(245, 245, 245);
    /* aspect-ratio: 1/1; */
}

.active-team .player-card{
    /* outline: red 1px solid; */
    /* transform: rotate3d(1, 1, 1, 90deg); */
    animation-name: rotatio;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    transform: center;
}

@keyframes rotatio {
    from{
        opacity: 0.5;
        rotate: 0 1 1 33deg;
    }
    to{
        opacity: 1;
        rotate: 0 0 0 0 0deg;
    }
}


.player-info{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    gap:0.5em;
    background: rgb(30, 30, 30);
    background: rgb(2, 6, 32);
    background: rgb(245, 245, 245);
    color: white;
    color: black;
    border-radius: 10px 10px 0 0 ;
    padding:0.25em 0.5em;
}

.star-player-card{
    min-width: 250px;
    min-height: 100px;
    background: rgb(35, 35, 35);
    border-radius: 10px;
    color: white;
    gap:0.5em;
    transition: 0.1s ease-in;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;

}

.star-content{
    padding: 0.5em 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25em;
}

.star-points{
    display: flex;
    /* align-items: center; */

    align-items: baseline;
    gap:0.1em;
    /* color: rgb(253, 253, 152);
    color: rgb(255, 255, 195); */

    margin: 0;
}


.star-points span{
    font-size: 2em;
    color: rgb(255, 255, 141);

    margin: 0;
    padding: 0 0.1em 0 0;
}

.star-stats{
    display: flex;
    gap: 0.5em;
}

.star-stats span{
    color: rgb(255, 255, 141);
    font-size: 1.25em;
}

.star-message{
    font-size: 1.15em;
    max-width: 25em;
    text-align: center;
    font-style: italic;
}

.star-player-card .player-info .vertical-divider{
    background: black;
    height: 10px;
    width:1px;
}

.star-player-card .player-info{
    background: black;
    color: white;
    /* color: rgb(253, 253, 152); */
    padding: 0.25em  0;
    gap: 0.25em;
    margin: 0;
    font-size: 1.3em;
}

@media screen and (max-width: 500px){
    .star-player-card{
        width: 80vw;
        padding: 0 0.5em 0.5em 0.5em;
    }
}

.player-name{
    text-transform: capitalize;
    padding:0;
    margin: 0;
    white-space: nowrap;
}


.player-position{
    /* text-transform: capitalize;
    font-weight: bold;
    font-size: 1.3em; */
    text-transform: uppercase;
    font-size: 0.75em;
    padding:0;
    margin: 0;
}

.player-card.add .remove-player-button, .player-card.add .captain-player-button{
    display:none;
}

.player-card.remove .add-player-button{
    display: none;
}

.captain-player-button img{
    opacity: 0.6;
    transition:0.3s;
}


.captain-player-button:hover img{
    /* height:25px;
    width: 25px; */
    opacity: 0.8;
}

.remove-player-button{

}

.icon-button img{
    /* height: 100%; */
    height:20px;
    width:20px;
    filter: invert(1);

}

.icon-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    /* border: 2px solid green; */
    height: 100%;
}

.icon-button:hover{
    cursor: pointer;
}

.button-label{
    font-size: 0.8em;
    text-align: center;
}

.player-card.captain .captain-player-button img{
    opacity: 1;
}

.player-card.captain .player-info{
    background:rgb(255, 230, 91);
    color: black;
    font-weight: 500;

}

.player-card.captain{
    outline: 2px solid rgb(255, 229, 83);
}

.player-card.captain .vertical-divider{
    background: black;
}

.player-card.captain .player-points{
    font-weight: 500;
}

.modifiable .player-points{
   display: none;
   font-weight: 500;
}

.unmodifiable .gameweek-modifier{
    display: none;
}

.modifiable .breakdown-button {
    display: none;
}
