.header-menu{
    /* border: 1px solid var(--css-red); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:2em;
    overflow-x: hidden;

    z-index: 3;

    padding:0.2em;

    /* height:80px; */
    justify-content: center;

    width: 100vw;
    background-color: white;
    color: var(--dark-text);

    font-size: 16px;
    display: none;
}

header{
    z-index: 3;
    box-shadow: 2px 0px 3px 1px rgba(128, 128, 128, 0.566);
    border-bottom: rgba(68, 68, 68, 0.452) 1px solid;
}

.navbar-title{
    font-size: 2em;
}

.header-menu li{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-menu a{
    color: var(--dark-text);
    text-decoration: none;
}

.header-menu a:hover{
    text-decoration: underline
}

.beta{
font-style: italic;
    font-size: 1rem;
}


@media screen and (max-width: 500px) {
    .header-menu{
        /* position: fixed; */
        background: white;
        color: black;
        box-shadow: 0 2px 3px 1px rgba(144, 43, 43, 0.307);
    }

    .header-menu h1{
        font-size: 1.35em;
    }
}