.manage-players-container{
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 1em;
}

.manage-players-container input{
    padding:0.5em;
    /* width: fit-content; */
    text-align: left;

    width: 9em;
}

.player-table-cell{
    width: 80%;
}

.position-table-cell{
    width: 20%;
}

.position-input{
    width: 100%;
}

.player-input{
    width: 100%;
}

#admin-player-table td{
    /* width: 5em; */
    /* width: 100%; */
    /* width: 5em; */
    width: 10em;
}

#admin-player-table tr{
    /* width: 100%; */
    /* width: 5em; */
    text-align: left;
}

.manage-players-container * {
    /* border: 0px solid blue; */
}

.manage-players-container{
    max-width:300px;
    /* border: 2px solid red; */
}

#admin-player-table{
    /* width: 50%; */
    /* width: 200px; */
    /* border: 2px solid green; */
    max-width: 80vw;
}

.add-player-container{
    display: flex;
    flex-direction: column;
    background-color: rgb(223, 223, 223);
    width: 100%;

    padding: 1em;

    gap: 3em;    
}

#add-player-button{

}

@media screen and (max-width: 500px) {
    .manage-players-container{

    }
    
}
