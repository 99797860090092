


.admin-panel-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1em;
    padding-bottom: 10em;

    --dark-bg: rgb(23, 23, 23);
    --dark-text: black;

    --accent-bg: red;
    --accent-text: red;

    --light-bg: rgb(231, 231, 231);
    --light-text: white;

}


.default-admin-button{
    background-color: var(--dark-bg);
    color: var(--light-text);
    padding: 1em;

    border-radius: 0.5em;
}

.admin-panel-container *{
    /* padding: 10px; */
}


.add-player-container.hidden{
    display: none;
}

.add-player-container{
    display: flex;
    flex-direction: column;
    gap:0.5em;
}

.add-player-container > input{
    padding:1em;
}


.gameweek-controls-container.hidden{
    display: none;

}

.gameweek-controls-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1em;

    /* width: clamp(100%, 800px, 1200px); */
    border: 0px solid red;
}


.current-gameweek-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hidden{
    display: none;
}

.number-picker-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0px solid red;
    /* height: 2em; */
    /* width: fit-content; */

    /* border: 2px solid blue; */


}




.number-picker-container *{
    border: 0px solid blue;

}

.increment-buttons-container{
    display: flex;
    align-items: center;
}

.number-picker-container label{
    text-wrap: nowrap;
} 

.number-picker-container p {
    /* width: 2em; */
    padding:0.35em;
    text-align: center;
    text-wrap: nowrap;
}

.number-picker-container button {
        width: 1.5em;
        /* border-radius: 10%; */
        background: rgb(63, 63, 63);
        color: white;
}

.large{
    font-size: 1.2em;
    width: 90%;
    border: 0px solid blue;
}

.large > .increment-buttons-container{
    width:clamp(200px,95vw,600px);
    justify-content: space-between;
    border: 0px solid blue;
}

.large  button{
    font-size: 1rem;
    height: 2.5em;
    width: 2.5em;
    border-radius: 6px;
    /* background-color: rgb(61, 60, 60);  */
    background-color: white;
    color: black;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.209);
    transition: 0.15s ease-in;

    /* justify-content: space-between;
    border: 0px solid blue; */
}

.large button:not(:focus):hover{
    scale: 1.12;
}

.large button:focus{
    scale: 1;
}

.performance-row-container{
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap:2em;
    padding: 1em 0.5em;
}

tr{
    display: flex;
    align-items: center;
    gap:2em
}

td{
    /* padding:10px; */
}

.table-player-name{
    text-align: right;
    width: 7em;
}

input{
    border: 2px solid grey;
}

.player-present{
    color: blue;
}

.player-absent{
    color: red;
}

.player-absent:hover{
    cursor: pointer;
}

.player-present:hover{
    cursor: pointer;
}


.gameweek-admin-api-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
}

.gameweek-admin-api-buttons button{
    padding: 0.5em;
}



.current-gameweek-settings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding:1em;

    border-radius: 0.5em;

    background-color: var(--light-bg);
}

.current-gameweek-settings button{
    background-color: var(--dark-bg);
    color: var(--light-text);
    padding: 1em;

    border-radius: 0.5em;
}

#latest-week-input{
    padding:0.5em;
}