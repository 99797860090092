body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div{
  border: 0px solid black;
}

html{
  --css-red: rgb(213, 63, 63);
  --dark-bg: black;
  --light-bg: rgb(240,240,240)
  --dark-text: black;
  --light-text: white;

  --navbar-height: 80px;

    width: 100%;
    max-width:100%;

}

body{
  width: 100%;
  max-width: 100%;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* transition: 0.3s; */
  border: 0px solid lightblue;
}

button{
  padding:0.25em;
}

button:hover{
  cursor: pointer;
}

@media screen and (max-width: 500) {
  html{
    --navbar-height: 40px;
  }
}