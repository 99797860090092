.leaderboard-page{
    padding: 1em;
    width: 80vw;
    border: 0px solid green;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2em;

    background-color: whitesmoke;
}

.table-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7em;
}

table{
    border:2px solid rgba(0, 0, 0, 0.388);
    border-radius:5px;
    background: white;
}

tr{
    padding:0.25em 0.5em;
    border-bottom: 1px rgba(0, 0, 0, 0.335) solid;
}

td{
    background-color:none;
    text-transform: capitalize;
}

tr.highlighted-user{
    background: rgba(115, 207, 230, 0.58);
}

.rank{
    text-align: right;
}
