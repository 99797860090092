@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


:root{
    --css-red: rgb(240, 63, 63);
}

body{
    font-family: 'Roboto', sans-serif;
}

body{
    width: fit-content;
    margin: auto;
    overflow-x: hidden;
}

.TeamViewer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background:rgb(236, 236, 236);
    gap:1em;
    /* padding:1em 0.25em; */
    padding:1em 0em;

    color: white;

    overflow-x: hidden;
    max-width: 100%;
    width: 100vw;
    margin: 0em auto;
    background-color: rgba(23, 23, 23, 0.853);
    /* background: rgb(53, 53, 53);
    color: white; */

}


.team-page-background{
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 100vw;
    background-color: green;
    z-index: -1;
}

.team-page-background img{
    display: block;
    width: 100%;
    
}

@media screen and (max-width:600px) {
    .team-page-background{
        position: fixed;
        top:0;
        left:0;
        height: 100vh;
        width: 100vw;
        background-color: green;
        z-index: -1;
    }
    
    .team-page-background img{
        display: block;
        height: 100%;
        
    }
    
}

.team-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1em;
    width: 100%;
    /* border: 2px solid green; */

}

.team-heading, .opponent-heading{
    text-transform: capitalize;
    font-weight: 500;
    white-space: nowrap;
    /* border: 2px solid green; */
}

.score-line{
    position: relative;
    display: flex;
    gap: 1em;
    /* justify-content: space-between; */
    /* width: fit-content; */
}

.gameweek-info{
    display: flex;
    flex-direction: column;
    gap:1em;
}



.score-line * {
    border: 0px red solid;
  }
.box {
    font-size: 1.2em;
    flex: 1;
    display: flex;
    justify-content: center;
    /* white-space: nowrap; */
    text-align: center;
    align-items: center;
    border: 0px solid green;
    min-width: 2em;
}

.box.right , .box.left{
    min-width: 10em;
}

.box.centre{
    max-width: 4em;
}

.box.left{
    border: 0px solid blue;
    text-align: right;
}

.box p{
    display: block;
    text-align: center;
    width: 100%;
    /* width: 100%; */
}

.box.left p{
    text-align: right;
}

.box.right p{
    text-align: left;
}

  
/* .box:first-child > p { margin-right: auto; }
  
.box:last-child  > p { margin-left: auto;  } */



.team-heading{
    display: none;
    width: 100vw;
    max-height: 100vw;
    margin: 0;
    text-align: center;
    background-color: var(--css-red);
    color: white;
    padding: 0.5em;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.39);
    margin-bottom: 0.5em;
}



details{
    width: clamp(20em, 40em,80%);
    text-align: center;
}

.active-squad-container{
    display: flex;
    position: relative;
    /* padding:3em; */
    display: grid;
    gap:1.5em;
    justify-items: center;
    align-items: center;
    /* background-color: red; */
    /* min-height: 30vh; */
    min-height: 300px;
    /* width: clamp(300px, 700px, 100%); */
    /* width:100%; */
    padding: 1.5em;
    padding-bottom:1em;

    outline:0px solid red;
    margin-bottom: 1.5em;

    border: 2px solid rgba(255, 255, 255, 0.704);
    border-radius: 10px;

}




.active-team{
    position: relative;
    width: clamp(300px, 700px, 95vw);
    aspect-ratio: 16/9;
    /* height:500px; */
    outline: 0px solid red;
    /* z-index:1; */
            
}



.active-team .player-card{
    position: absolute;
    transform: translateX(-50%);
    width: 25%;
    aspect-ratio: 5/4;
    /* perspective-origin: top; */
    /* transform: rotate3d(1, 0, 0, 50deg); */
    transition: 0.2s ease-out;
    /* z-index:5; */

    /* transform: translate(-50%, -50%); */
    /* z-index: auto; */
}


.active-team > :nth-child(1) {
    top: 5%;
    left: 50%;
    width: 30%;
    /* grid-area: 0/2; */
}

.active-team > :nth-child(2) {
    left: 15%;
    top:15%;
    /* grid-area: 0/2; */
}

.active-team > :nth-child(3) {
    right: 15%;
    top:15%;
    transform: translateX(50%);

    /* grid-area: 0/2; */
}

.active-team > :nth-child(4) {
    bottom:5%;
    left:30%;
    /* grid-area: 0/2; */
}

.active-team > :nth-child(5) {
    bottom:5%;
    right:30%;
    transform: translateX(50%);

    /* grid-area: 0/2; */
}
/* .active-team > {
    position:static;
} */

/* .active-team > * {
    z-index:2;
} */

/* tbody > :nth-child(2n - 1){
    background-color: lightgrey;
} */


.active-squad-background{
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: rgb(63, 23, 23); */
}

.active-squad-background img{
    display: block;
    /* width: 100%; */
    height: 100%;
    position: absolute;
    width: 100%;
    opacity: 0.75;
    /* filter: blur(1px); */
    background: black;
}

.floating-options{
    position: absolute;
    color: black;
    top: 100%;
    transform: translate(0, -50%);
    background-color: white;
    padding:0.75em 2em;
    border-radius: 20px;
    font-size: 1.2em;
}

.floating-options.current-week{
    /* box-shadow: 0px 1px 2px 0px rgb(46, 46, 46); */
    transform: translate(0, -50%);
    padding:0em 0em;
    background-color: transparent;

}

.floating-options.current-week .floating-points{
    display: none;
}

.submit-selection-button{
    width: clamp(20em, 0em, 90vw);
    width: auto;
    height: fit-content;
    z-index: 2;
    font-size: 1.15em;
    /* background-color: blue; */
    background-color: white;
    background-color: var(--css-red);
    background-color: rgba(35, 35, 35, 0.812);
    background-color: white;
    border: 2px solid black;
    color: black;
    
    padding: 0.3em 0.5em;
    /* box-shadow: 0px 0px 3px 1px var(--css-red); */
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px rgba(177, 96, 96, 0.391);

    /* color:rgb(57, 57, 57); */
    border: 2px solid black;


}


.TeamViewer .view-full-leaderboard-button{
    background: none;
    font-size: 1.1em;
    text-decoration: underline;
    color: white;
}

.view-leaderboard-button.api-button{
    text-align: center;
    width: fit-content;
    margin: auto;
}

.TeamViewer .view-full-leaderboard-button p span{
    font-size: 1.5rem;
}

.floating-points{

    /* box-shadow: 0px 2px 2px 2px black; */
}

.team-page-weekly-leaderboard{
    color: black;
}

.mini-team-viewer{
    position: fixed;
    top:50%;
    left: 50%;
    width: 400px;
    width: clamp(300px, 400px, 80vw);
    /* height: 300px; */
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    gap: 1.5em;
    font-size: 1.25rem;
    background: white;
    border: 5px solid black;
    color: black;
}

.mini-team-viewer h3{
    text-align: center;
    text-transform: capitalize;
}

.mini-team-players-container{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap:0.15em;
}

.mini-team-viewer p{
    text-transform: capitalize;
}

.mini-team-viewer button{
    padding: 0.5em 2em;
    outline: 1px solid black;

}

.mini-team-viewer button:hover{
    outline: 3px solid black;
}


tr.clickable:hover, tr.clickable:focus{
    cursor: pointer;
    background: rgb(211, 211, 211);
}

.all-players-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap:2em;
    padding:2em;
    color: black;
    background-color: rgb(216, 216, 216);
    background-color: rgb(166, 166, 255);
    background-color: rgb(242, 241, 236);
    min-height:70vh;
}

.star-players-container{
    display: flex;
    flex-direction: column;
    padding:1em 6em;
    gap:3em;
    align-items: center;
}

.star-players{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3em;
}

@media  screen and (max-width:500px) {
    .star-players-container{
        display: flex;
        flex-direction: column;
        padding: 2em 0em;
        gap:2em;
        align-items: center;
    }
    
    .star-players{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }
   
}

.all-players-container{
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: repeat( auto-fill, minmax(50px, 1fr) ); */
    transition: 0.5s;
    gap: 2em;
}

.all-players-filters-container{
    display: flex;
    gap:1em;
    padding:1em;
}

.filter-button{
    padding:0.5em 1em;
    cursor: pointer;

    background: white;
    color: black;
    border-radius: 6px;
    
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.318);
    transition: 0.2s;
}

.active-filter{
    background: black;
    color: white;
}




input[type="number"]{
    text-align: center;
    width:4em;
    margin: 0;
    padding: 0;
}

.api-button{
    border: none;
    border-radius: 0;
    background: white;
    padding:0.5em;
    border: 2px solid black;
    cursor: pointer;
    color: black;
}

.api-button:hover{
    scale: 1.01;
    transition: 0.05s;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.456);
}


.admin-button{


}



@media screen and (max-width:600px) {
    
    html{
        font-size: 13px;
    }

    .TeamViewer{
        gap:0.5em;
    }

    .all-players-section{
        gap:0.75em;
        padding:0.25em 0.25em 4.75em 0.25em ;
    }

    .all-players-container{
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        padding:0.25em auto 1em auto;
        /* height:300px;
        overflow: scroll; */
        gap: 1.5em 1em;
    }


    .active-squad-container{
        width: 98%;
        justify-content: center;
        gap:1em;
        min-height: 250px;
        padding: 2em 0.5em;
        border: 1px solid white;
        /* aspect-ratio: 16/12; */


    }
    .active-team{
        aspect-ratio: 16/11;
    }
}

@media screen and (min-width:1200px) {
    .TeamViewer{
        /* display: grid;
        grid-template-columns: 1fr 1fr; */
    }

    
}